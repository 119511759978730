import {
  fetchPageItem,
  edit,
  createLastMonth,
  creatInovicePdf,
  sendEmail,
} from '@/api/finance/financeAffiliateBill';
import { mapState, mapActions } from 'vuex';
import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';
import { selectProds } from 'api/product/overall';
import { getPidsList } from 'api/product/rankSearch';
import { filterObject } from 'utils/utils';
import qs from 'querystring';
import { approvalAttachment } from '@/api/finance/bussinessProcess';
import FinanceAffiliateBillItem from '../../finance/financeAffiliateBillItem';

export default {
  components: {
    Pagination,
    GroupSelect,
    FinanceAffiliateBillItem,
  },
  data() {
    return {
      filterForm: {
        page: 1,
        pageSize: 20,
        dateRange: [],
        showOffer: true,
        billId: 0,
      },
      total: 0,
      postData: {},
      list: [],
      loading: {
        list: false,
        submitBtn: false,
      },
      billItemVisible: false,
      mmpRevenue: 0,
      affiliateFinance: {
        id: 0,
        confirm: 0,
        confirmRatio: 0,
        note: '',
      },
      dialog: {
        visible: false,
        visibleAttachments: false,
        title: '编辑',
        type: '',
      },
      multipleSelection: [],
      copyArr: [],
      rules: {
        confirmRatio: [
          { required: true, type: 'number', message: 'confirmRatio不能为空', trigger: 'blur' },
        ],
      },
      statusList: [
        {
          label: 'created',
          value: '0',
        },
        {
          label: 'confirm',
          value: '1',
        },
        {
          label: 'AM confirm',
          value: '2',
        },
      ],
      prodList: [],
      pidList: [],
      financeAttachments: [],
      businessTypes: [
        {
          label: 'overseas',
          value: 'overseas',
        },
        {
          label: 'domestic',
          value: 'domestic',
        },
      ],
      cpis: [
        {
          label: 'cpi',
          value: 'cpi',
        },
        {
          label: 'cpa',
          value: 'cpa',
        },
      ],
    };
  },
  mounted() {
    this.prodsList();
  },
  methods: {
    // get Source
    ...mapActions('source', ['getSourceIdsList']),
    // get Affiliate
    ...mapActions('affiliate', ['getAffiliateList']),
    init(row) {
      this.filterForm.billId = row.id;
      this.getList();
    },
    // 获取列表
    // 获取prods
    prodsList() {
      selectProds()
        .then((res) => {
          this.prodList = res.result;
        })
        .catch(() => {
          this.prodList = [];
        });
    },
    getList(page) {
      if (page) {
        this.filterForm.page = page;
      }
      let param = { ...this.filterForm };
      if (this.filterForm.sourceIdList) {
        param.sourceIdList = this.filterForm.sourceIdList.toString();
      }
      if (this.filterForm.affiliateIds) {
        param.affiliateIds = this.filterForm.affiliateIds.toString();
      }
      if (this.filterForm.prods) {
        param.prods = this.filterForm.prods.toString();
      }
      if (this.filterForm.pids) {
        param.pids = this.filterForm.pids.toString();
      }
      this.loading.list = true;
      fetchPageItem(param)
        .then((res) => {
          if (res.code === 200) {
            this.list = res.result;
            this.total = res.total;
          } else {
            this.$message.error(res.message);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.loading.list = false;
          this.$message.error(e.message);
        });
    },
    creatInvoice(id) {
      this.loading.list = true;
      creatInovicePdf(id).then((response) => {
        if (response.success === true) {
          this.$message({
            message: 'creatInovice Success',
            type: 'success',
          });
          this.getList();
        } else {
          this.$message.error('Update  Error:' + response.message);
          this.loading.list = false;
        }
      });
    },
    openFile(row) {
      window.open(row.billFileUrl, '_blank');
    },
    sendToEmail(id) {
      this.$confirm('此操作将 send email?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          this.loading.list = true;
          sendEmail(id).then((response) => {
            if (response.success === true) {
              this.loading.list = false;
              this.$message({
                message: 'sendEmail Success',
                type: 'success',
              });
              this.getList();
            } else {
              this.loading.list = false;
              this.$message.error('Update  Error:' + response.message);
            }
          });
        })
        .catch(() => {
          this.loading.list = false;
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    // 打开弹窗
    openDialog(row) {
      this.affiliateFinance.confirm = row.confirm;
      this.affiliateFinance.confirmRatio = row.confirmRatio * 100;
      this.affiliateFinance.note = row.note;
      this.affiliateFinance.prod = row.prod;
      this.affiliateFinance.sourceId = row.sourceId;
      this.affiliateFinance.affiliateId = row.affiliateId;
      this.affiliateFinance.financeCycle = row.financeCycle;
      this.affiliateFinance.payout = row.payout;
      this.dialog.visible = true;
    },

    // 打开弹窗
    openAffDialog(row) {
      let param = {
        prod: row.prod,
        sourceId: row.sourceId,
        financeCycle: row.financeCycle,
        status: 3,
      };
      approvalAttachment(param).then((res) => {
        this.financeAttachments = res.result;
      });
      this.dialog.visibleAttachments = true;
    },

    handleSizeChange(size) {
      this.filterForm.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.filterForm.page = currentPage;
      this.getList();
    },
    update() {
      this.$refs['affiliateFinanceForm'].validate((valid) => {
        if (valid) {
          this.affiliateFinance.confirmRatio = this.affiliateFinance.confirmRatio / 100;
          edit({ ...this.affiliateFinance }).then((response) => {
            if (response.success === true) {
              this.$message({
                message: 'Update Success',
                type: 'success',
              });
              this.getList();
              this.dialog.visible = false;
              this.affiliateFinance = {
                id: 0,
                confirm: 0,
                confirmRatio: 0,
                note: '',
              };
            } else {
              this.$message.error('Update Error:' + response.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.copyArr = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        this.copyArr.push(this.multipleSelection[i].id);
      }
      console.log(this.copyArr.toString());
    },
    // 批量新增上月数据
    insertBatch() {
      this.$confirm('此操作将批量生成上月数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          createLastMonth({}).then((res) => {
            if (res.success === true) {
              this.$message.success('新增成功！');
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    // 下载excel表格
    downloadFie() {
      this.$confirm('最多下载10万条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          let param = { ...this.filterForm };
          if (this.filterForm.sourceIdList) {
            param.sourceIdList = this.filterForm.sourceIdList.toString();
          }
          if (this.filterForm.affiliateIds) {
            param.affiliateIds = this.filterForm.affiliateIds.toString();
          }
          if (this.filterForm.prods) {
            param.prods = this.filterForm.prods.toString();
          }
          if (this.filterForm.pids) {
            param.pids = this.filterForm.pids.toString();
          }
          let rangeTime = this.filterForm.dateRange;
          if (Array.isArray(rangeTime) && rangeTime.length > 0) {
            param.startTime = this.filterForm.dateRange[0];
            param.endTime = this.filterForm.dateRange[1];
            // 删除多余的属性
            delete param.dateRange;
          }

          param = filterObject(param);
          let strParam = qs.stringify(param);
          let url = '/api/finance/affiliate/download?' + strParam;
          console.log(url);
          window.open(url, '_blank');
        })
        .catch((error) => {
          this.$message({
            type: 'info',
            message: error.message,
          });
        });
    },
    // 排序监听
    sortChange(column) {
      if (column.prop !== null && column.order !== null) {
        if (column.prop === 'eventCount') {
          this.filterForm.sorting = 'event_count';
        } else {
          this.filterForm.sorting = column.prop;
        }
        this.filterForm.sortType = column.order === 'ascending' ? 'asc' : 'desc';
      } else {
        this.filterForm.sorting = 'id';
        this.filterForm.sortType = 'desc';
      }
      this.getList();
    },
    formatValue(val) {
      if (val === '0') {
        return 'create';
      } else if (val === '1') {
        return 'send';
      } else {
        return val;
      }
    },
    idDisableFun(val) {
      return !(val === '0');
    },
    computeConfirmNum() {
      let num = ((this.affiliateFinance.confirmRatio / 100) * this.affiliateFinance.payout).toFixed(
        3
      );
      this.affiliateFinance.confirm = num;
    },
    getPids() {
      getPidsList().then((res) => {
        this.pidList = res.result;
      });
    },
    computeRatio(row) {
      if (isNaN(row.confirmAf) || isNaN(row.confirmBd)) {
        return 0;
      }
      if (row.confirmAf === 0) {
        return 100;
      } else {
        return ((row.confirmBd / row.confirmAf) * 100).toFixed(3);
      }
    },
  },
  computed: {
    ...mapState('source', {
      sourcesList: (state) => state.sourcesList,
      sourceLoading: (state) => state.sourceLoading,
    }),
    ...mapState('affiliate', {
      affiliatesGroupList: (state) => state.affiliatesGroupList,
      affiliateLoading: (state) => state.affiliateLoading,
    }),
  },
};
