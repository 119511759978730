<template>
  <div class="whiteList">
    <div class="mainBox">
      <el-card shadow="hover" class="mb10">
        <el-form v-model="filterForm" size="small" label-width="80px">
          <el-row
            :gutter="10"
            type="flex"
            justify="start"
            style="flex-wrap: wrap; flex-direction: row"
          >
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <el-form-item label="prod:" class="mb5">
                <el-select v-model="filterForm.prods" clearable multiple filterable>
                  <el-option
                    v-for="item in prodList"
                    :key="item"
                    :value="item"
                    :label="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="mt5">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" align="right">
              <el-form-item label-width="0" class="mb5">
                <el-button type="primary" :loading="loading.list" @click="getList(1)"
                  >Search</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="loading.list"
          ref="tableRef"
          style="width: 100%; overflow-x: auto"
          stripe
          border
          :data="list"
          tooltip-effect="dark"
          aria-setsize="mini"
          size="mini"
          class="mb10"
          max-height="800"
          @sort-change="sortChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column label="Period" prop="financeCycle" align="center"></el-table-column>
          <el-table-column
            label="AffiliateName"
            prop="affiliateName"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="prod" prop="prod" align="center"> </el-table-column>
          <el-table-column
            label="Events"
            prop="eventCount"
            align="center"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="Installs"
            prop="installs"
            align="center"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="Payout"
            prop="payout"
            align="center"
            sortable="custom"
          ></el-table-column>
          <el-table-column label="Rejects" prop="rejects" align="center"></el-table-column>
          <el-table-column label="Confirm %" prop="confirmRatio" align="center">
            <template slot-scope="scope">
              {{ (scope.row.confirmRatio * 100).toFixed(2) }}%
            </template>
          </el-table-column>
          <el-table-column label="Confirm Num" prop="confirm" align="center"></el-table-column>
        </el-table>
        <el-dialog :title="dialog.title" :visible.sync="dialog.visible" width="30%">
          <el-form
            :model="affiliateFinance"
            size="small"
            ref="affiliateFinanceForm"
            label-width="auto"
            :rules="rules"
          >
            <el-form-item label="confirm:" prop="confirm">
              <el-input
                v-model="affiliateFinance.confirm"
                disabled
                placeholder="Please enter confirm"
              ></el-input>
            </el-form-item>
            <el-form-item label="confirmRatio:" prop="confirmRatio">
              <el-input
                v-model.number="affiliateFinance.confirmRatio"
                placeholder="Please enter confirm %"
                @blur="computeConfirmNum()"
              >
                <template slot="append">%</template></el-input
              >
            </el-form-item>
            <el-form-item label="note:" prop="note">
              <el-input
                type="textarea"
                v-model="affiliateFinance.note"
                placeholder="Please enter note"
                maxlength="255"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialog.visible = false">取 消</el-button>
            <el-button type="primary" @click="update()">确 定</el-button>
          </span>
        </el-dialog>
        <pagination
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="filterForm.pageSize"
          :currentPage="filterForm.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
  import FinanceAffiliateBill from '../controllers/finance/financeAffiliateBillItem';
  export default {
    name: 'financeAffiliateBill',
    ...FinanceAffiliateBill,
  };
</script>

<style scoped>
  .table-row {
    height: 50px;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* 将 align-items 改为 flex-start，使按钮顶部对齐 */
    flex-wrap: wrap; /* 添加 flex-wrap 属性，使按钮换行显示 */
    width: 100%; /* 设置容器的宽度为100% */
  }
  .button-group .el-button {
    margin-bottom: 10px; /* 添加按钮之间的间距，可根据需要进行调整 */
  }
  .el-progress {
    width: 35%;
  }
  .progress-label {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
  }
  .card-div-files {
    display: block;
    border-radius: 3px;
    margin-top: 5px;
    border: 1px solid rgb(228, 224, 224);
    padding: 5px;
  }
  .card-div-files a {
    color: rgb(119, 173, 243);
  }
</style>
