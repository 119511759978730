<template>
  <div class="whiteList">
    <div class="mainBox">
      <el-card shadow="hover" class="mb10">
        <el-form v-model="filterForm" size="small" label-width="80px">
          <el-row
            :gutter="10"
            type="flex"
            justify="start"
            style="flex-wrap: wrap; flex-direction: row"
          >
            <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <el-form-item label="Affiliate(s):" class="mb5">
                <group-select
                  v-model="filterForm.affiliateIds"
                  :data-source="affiliatesGroupList"
                  class="w100"
                  multiple
                  :loading="affiliateLoading"
                  clearable
                  reserve-keyword
                  filterable
                  collapse-tags
                  placeholder="Please select Affiliate"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="status" class="mb5">
                <el-select v-model="filterForm.status" multiple clearable style="width: auto">
                  <el-option
                    v-for="item in statusList"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="7" :md="7" :lg="7" :xl="7">
              <el-form-item label="period:" class="mb5">
                <el-date-picker
                  v-model="filterForm.dateRange"
                  clearable
                  type="monthrange"
                  value-format="yyyy-MM"
                  range-separator="to"
                  start-placeholder="start"
                  end-placeholder="end"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="mt5">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" align="right">
              <el-form-item label-width="0" class="mb5">
                <el-button type="primary" :loading="loading.list" @click="getList(1)"
                  >Search</el-button
                >
                <el-button type="info" @click="downloadFie">download</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="loading.list"
          ref="tableRef"
          style="width: 100%; overflow-x: auto"
          stripe
          border
          :data="list"
          tooltip-effect="dark"
          aria-setsize="mini"
          size="mini"
          class="mb10"
          max-height="800"
          @sort-change="sortChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column label="Period" prop="financeCycle" align="center"></el-table-column>
          <el-table-column
            label="AffiliateName"
            prop="affiliateName"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span @click="detailAutoMixClick(scope.row)" class="cor337ab7">{{
                scope.row.affiliateName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="status" prop="status" align="center">
            <template slot-scope="scope"> {{ formatValue(scope.row.status) }} </template>
          </el-table-column>
          <el-table-column
            label="Events"
            prop="eventCount"
            align="center"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="Installs"
            prop="installs"
            align="center"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="Payout"
            prop="payout"
            align="center"
            sortable="custom"
          ></el-table-column>
          <el-table-column label="Rejects" prop="rejects" align="center"></el-table-column>
          <el-table-column label="Confirm %" prop="confirmRatio" align="center">
            <template slot-scope="scope">
              {{ (scope.row.confirmRatio * 100).toFixed(2) }}%
            </template>
          </el-table-column>
          <el-table-column label="Confirm Num" prop="confirm" align="center"></el-table-column>
          <el-table-column label="Cover" prop="cover" align="center"></el-table-column>
          <el-table-column
            label="lastMonthBalance"
            prop="lastMonthBalance"
            align="center"
          ></el-table-column>
          <el-table-column label="Invoice File" prop="billFileName" align="center">
            <template slot-scope="scope">
              <span @click="openFile(scope.row)" class="cor337ab7">{{
                scope.row.billFileName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="send number" prop="sendNum" align="center"></el-table-column>
          <el-table-column label="Action" align="center" width="150" fixed="right">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-circle-plus"
                circle
                size="mini"
                v-if="scope.row.id"
                @click="creatInvoice(scope.row.id)"
                title="generate word"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-s-promotion"
                circle
                size="mini"
                v-if="scope.row.id"
                @click="sendToEmail(scope.row.id)"
                title="send email"
              ></el-button>
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-s-claim"
                circle
                @click="toApplyApproval(scope.row)"
                :disabled="scope.row.status === '2'"
                v-show="scope.$index !== 0"
                title="Cover"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog :title="dialog.title" :visible.sync="dialog.visible" width="30%">
          <el-form
            :model="affiliateFinance"
            size="small"
            ref="affiliateFinanceForm"
            label-width="auto"
            :rules="rules"
          >
            <el-form-item label="confirm:" prop="confirm">
              <el-input
                v-model="affiliateFinance.confirm"
                disabled
                placeholder="Please enter confirm"
              ></el-input>
            </el-form-item>
            <el-form-item label="confirmRatio:" prop="confirmRatio">
              <el-input
                v-model.number="affiliateFinance.confirmRatio"
                placeholder="Please enter confirm %"
                @blur="computeConfirmNum()"
              >
                <template slot="append">%</template></el-input
              >
            </el-form-item>
            <el-form-item label="note:" prop="note">
              <el-input
                type="textarea"
                v-model="affiliateFinance.note"
                placeholder="Please enter note"
                maxlength="255"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialog.visible = false">取 消</el-button>
            <el-button type="primary" @click="update()">确 定</el-button>
          </span>
        </el-dialog>
        <el-dialog title="Affiliate" :visible.sync="billItemVisible" top="3vh" width="80%">
          <finance-affiliate-bill-item v-if="billItemVisible" ref="addOrUpdate" />
          <div align="center" class="pt10">
            <el-button @click="billItemVisible = false" size="small">cancel</el-button>
          </div>
        </el-dialog>
        <pagination
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="filterForm.pageSize"
          :currentPage="filterForm.page"
        ></pagination>
        <el-dialog
          title="Affiliate Approval"
          :visible.sync="applyApproval.visible"
          width="800px"
          top="3vh"
        >
          <el-row>
            <el-form
              :model="applyApprovalForm"
              size="mini"
              ref="applyApprovalForm"
              label-width="200px"
              :rules="applyRules"
            >
              <el-col :xs="24" :lg="24" :md="24" :sm="24">
                <el-form-item
                  class="approval-input-width"
                  label="Affiliate Name"
                  prop="affiliateName"
                >
                  <el-input
                    v-model="applyApprovalForm.affiliateName"
                    disabled
                    placeholder="affiliateName"
                  ></el-input>
                </el-form-item>
                <el-form-item class="approval-input-width" label="payout" prop="payout">
                  <el-input v-model="applyApprovalForm.payout" disabled></el-input>
                </el-form-item>
                <el-form-item
                  class="approval-input-width"
                  label="confirmRatio"
                  prop="confirmRatio"
                  align="left"
                >
                  <span>{{ (applyApprovalForm.confirmRatio * 100).toFixed(2) }}%</span>
                </el-form-item>
                <el-form-item class="approval-input-width" label="confirm" prop="confirm">
                  <el-input
                    v-model="applyApprovalForm.confirm"
                    disabled
                    placeholder="confirm"
                  ></el-input>
                </el-form-item>
                <el-form-item class="approval-input-width" label="Cover Amount" prop="coverAmount">
                  <el-input v-model="applyApprovalForm.cover" placeholder="0.00"></el-input>
                </el-form-item>
                <el-form-item class="approval-input-width" label="Note 1" prop="note1">
                  <el-input
                    :autosize="{ minRows: 3, maxRows: 10 }"
                    maxlength="255"
                    show-word-limit
                    type="textarea"
                    v-model="applyApprovalForm.note1"
                    placeholder=""
                  ></el-input>
                </el-form-item>
                <el-form-item class="approval-input-width" label="Note 2" prop="note2">
                  <el-input
                    :autosize="{ minRows: 3, maxRows: 10 }"
                    maxlength="255"
                    show-word-limit
                    type="textarea"
                    v-model="applyApprovalForm.note2"
                    placeholder=""
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-form>
          </el-row>
          <el-row>
            <el-col :xs="24" :lg="24" :md="24" :sm="24" align="center">
              <el-button
                @click="applyApprovalSubmit"
                type="success"
                size="small"
                style="margin-left: 10%"
                >Submit</el-button
              >
            </el-col>
          </el-row>
        </el-dialog>
      </el-card>
    </div>
  </div>
</template>

<script>
  import FinanceAffiliateBill from '../controllers/finance/financeAffiliateBill';
  export default {
    name: 'financeAffiliateBill',
    ...FinanceAffiliateBill,
  };
</script>

<style scoped>
  .table-row {
    height: 50px;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* 将 align-items 改为 flex-start，使按钮顶部对齐 */
    flex-wrap: wrap; /* 添加 flex-wrap 属性，使按钮换行显示 */
    width: 100%; /* 设置容器的宽度为100% */
  }
  .button-group .el-button {
    margin-bottom: 10px; /* 添加按钮之间的间距，可根据需要进行调整 */
  }
  .el-progress {
    width: 35%;
  }
  .progress-label {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
  }
  .card-div-files {
    display: block;
    border-radius: 3px;
    margin-top: 5px;
    border: 1px solid rgb(228, 224, 224);
    padding: 5px;
  }
  .card-div-files a {
    color: rgb(119, 173, 243);
  }
</style>
