import request from 'utils/request';
const api = {
  FINANCE_AFFILIATE_LIST: '/api/finance/affiliateBill/list',
  FINANCE_AFFILIATE_DOWNLOAD: '/api/finance/affiliateBill/download',
  FINANCE_AFFILIATE_ADD: '/api/finance/affiliateBill/save',
  FINANCE_AFFILIATE_CREATELASTMONTH: '/api/finance/affiliateBill/createLastMonth',
  FINANCE_AFFILIATE_UPDATE: '/api/finance/affiliateBill/update',
  FINANCE_AFFILIATE_BD_LIST: '/api/finance/affiliateBill/bd/list',
  FINANCE_AFFILIATE_TO_UPDATE: '/api/finance/affiliateBill/to/update',
  CREATE_INVOICE: '/api/finance/affiliateBill/createInvoice?id=',
  SEND_EMAIL: '/api/finance/affiliateBill/sendEmail?id=',
  FINANCE_AFFILIATE_ITEM_LIST: '/api/finance/affiliateBill/item/list',
};

export function fetchPage(params) {
  return request({
    url: api.FINANCE_AFFILIATE_LIST,
    method: 'get',
    params,
  });
}

export function fetchPageItem(params) {
  return request({
    url: api.FINANCE_AFFILIATE_ITEM_LIST,
    method: 'get',
    params,
  });
}

export function creatInovicePdf(id) {
  return request({
    url: api.CREATE_INVOICE + id,
    method: 'get',
    id,
  });
}

export function sendEmail(id) {
  return request({
    url: api.SEND_EMAIL + id,
    method: 'get',
    id,
  });
}

export function fetchBDPage(params) {
  return request({
    url: api.FINANCE_AFFILIATE_BD_LIST,
    method: 'get',
    params,
  });
}

export function save(data) {
  return request({
    url: api.FINANCE_AFFILIATE_ADD,
    method: 'post',
    data,
  });
}

export function createLastMonth(data) {
  return request({
    url: api.FINANCE_AFFILIATE_CREATELASTMONTH,
    method: 'post',
    data,
  });
}

export function edit(data) {
  return request({
    url: api.FINANCE_AFFILIATE_UPDATE,
    method: 'post',
    data,
  });
}

export function editTO(data) {
  return request({
    url: api.FINANCE_AFFILIATE_TO_UPDATE,
    method: 'post',
    data,
  });
}

export function download(params) {
  return request({
    url: api.FINANCE_AFFILIATE_DOWNLOAD,
    method: 'get',
    params,
  });
}
